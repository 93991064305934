import * as React from "react";
import useMatchMedia from "../hooks/useMatchMedia";

interface ISiteContext {
  isMobile: boolean;
}

export const SiteContext = React.createContext<ISiteContext>({
  isMobile: true,
} as ISiteContext);

const SiteProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [isMobile] = useMatchMedia(["(max-width: 675px)"]);
  return (
    <SiteContext.Provider value={{ isMobile }}>{children}</SiteContext.Provider>
  );
};

export default ({ element }) => <SiteProvider>{element}</SiteProvider>;
